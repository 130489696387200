import { ThemeOptions, colors, darken } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#158ECE",
    paper: colors.common.white,
  },
  primary: {
    main: darken("#158ECE", 0.1),
    contrastText: "#FAFAFA",
  },
  secondary: {
    main: "#2A2A72",
    light: "#FAFAFA",
    contrastText: "#ffffff",
  },
  error: {
    main: "#FE5E5B",
  },
  info: {
    main: darken("#158ECE", 0.1),
  },
  warning: {
    main: colors.red[500],
  },
  success: {
    main: darken("#158ECE", 0.1),
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
};

export default palette;
