import React from "react";
import { Box, Typography } from "@mui/material";

import { PageLayout } from "@APP/components";

const PrivacyNoticeScreen = () => {
  return (
    <PageLayout>
      <Box maxWidth={980} alignSelf="center" id="privacyPolicyBox">
        <Typography variant="h5">Privacy Notice</Typography>
        <br />
        <Typography variant="body2">
          <b>As a Finance Applicant</b>, we only collect personal information from you to provide
          Our Finance Application Service to you.
        </Typography>
        <br />
        <Typography variant="body2">
          We store all details that you provide, including your company details (if applicable),
          name, email address and mobile phone number when you register. This is so that we can
          submit the Finance Application on your behalf.
        </Typography>
        <br />
        <Typography variant="body2">
          We also ask you to provide the name and contact details for other people within your
          organisation that are relevant to the Finance Application.
        </Typography>
        <br />
        <Typography variant="body2">
          We take our responsibility seriously in protecting this information in line with GDPR. We
          are also ISO27001 accredited, which means that we adhere to proper policies and procedures
          in protecting all information.
        </Typography>
        <br />
        <Typography variant="body2">
          Under certain circumstances by law, you have the right to request access to your personal
          information (commonly known as a "data subject access request"). This enables you to
          receive a copy of the personal information we hold about you and to check that we are
          lawfully processing it.
        </Typography>
        <br />
        <Typography variant="body2">
          We may, in the future, ask permission to use this information for other services to you
          but we will always ask for your permission explicitly and explain what information we are
          using and why.
        </Typography>
        <br />
        <Typography variant="body2">
          We will share your data with member companies of Praetura Group Limited solely for the
          purposes of assessing and, if agreed, providing the finance you are applying for and
          improving the quality and access to products and services provided. You can find their
          privacy policies here:
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant="body2">
              Kingsway Finance:{" "}
              <a href="https://www.kingswayfinance.co.uk/privacy-policy/">
                https://www.kingswayfinance.co.uk/privacy-policy/
              </a>
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Praetura Asset Finance:{" "}
              <a href="https://www.praeturaaf.com/privacy-policy/">
                https://www.praeturaaf.com/privacy-policy/
              </a>
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Praetura Invoice Finance:{" "}
              <a href="https://www.praeturaaf.com/privacy-policy/">
                https://www.praeturaif.com/privacy-policy/
              </a>
            </Typography>
          </li>
        </ul>
        <br />
        <Typography variant="body2">
          You always have the right to erasure, which means the right for any personal information
          that we store for you to be removed in line with the law. If you would like us to remove
          your information or have any questions about your stored data and how it is protected,
          then please contact us at <a href="mailto:info@bankifi.com">info@bankifi.com</a>
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>As a Payee</strong>, we only collect personal information from you to provide Our
          Open Cash Management Service to you.
        </Typography>
        <br />
        <Typography variant="body2">
          We store your company details (if applicable), name, email address and mobile phone number
          when you register. This is so that we can send Request(s) For Payment on your behalf.
        </Typography>
        <br />
        <Typography variant="body2">
          We also ask you to provide the name and email address of any Payers that you wish to send
          Requests to Pay to.
        </Typography>
        <br />
        <Typography variant="body2">
          We take our responsibility seriously in protecting this information in line with GDPR. We
          are also ISO27001 accredited, which means that we adhere to proper policies and procedures
          in protecting all information.
        </Typography>
        <br />
        <Typography variant="body2">
          Under certain circumstances by law, you have the right to request access to your personal
          information (commonly known as a "data subject access request"). This enables you to
          receive a copy of the personal information we hold about you and to check that we are
          lawfully processing it.
        </Typography>
        <br />
        <Typography variant="body2">
          In order to use Our Open Cash Management Service, you give your consent through Open
          Banking for us to see your bank transactions so that we can check that payments have
          arrived in your bank account, which is done through your bank’s internet / mobile banking
          service. We have no access to further information within that service, including any
          specific account information such as your bank’s sort code or your account number. This
          means that these details are kept private to you when using our service.
        </Typography>
        <br />
        <Typography variant="body2">
          We may, in the future, ask permission to use this information for other services to you
          but we will always ask for your permission explicitly and explain what information we are
          using and why.
        </Typography>
        <br />
        <Typography variant="body2">
          You always have the right to erasure, which means the right for any personal information
          that we store for you to be removed in line with the law. If you would like us to remove
          your information or have any questions about your stored data and how it is protected,
          then please contact us at <a href="mailto:info@bankifi.com">info@bankifi.com</a>
        </Typography>
        <br />
        <Typography variant="body2">
          <b>As a Payer</b>, we do not collect any personal information from you through Our
          Service.
        </Typography>
        <br />
        <Typography variant="body2">
          We do store your name and email address which is provided to us by a Payee when the Payee
          sends a Request For Payment to you. This is so that we can process the Request for Payment
          and send it to you.
        </Typography>
        <br />
        <Typography variant="body2">
          We take our responsibility seriously in protecting this information in line with GDPR. We
          are also ISO27001 accredited, which means that we adhere to proper policies and procedures
          in protecting all information.
        </Typography>
        <br />
        <Typography variant="body2">
          Under certain circumstances by law, you have the right to request access to your personal
          information (commonly known as a "data subject access request"). This enables you to
          receive a copy of the personal information we hold about you and to check that we are
          lawfully processing it.
        </Typography>
        <br />
        <Typography variant="body2">
          When you make a payment using Our Service this is done through your bank’s internet /
          mobile banking service and we have no access to the information within that service,
          including any information relating to your account such as your bank’s sort code or your
          account number. This means that these details are kept private to you when using our
          service.
        </Typography>
        <br />
        <Typography variant="body2">
          You always have the right to erasure, which means the right for any personal information
          that we store for you to be removed in line with the law. If you would like us to remove
          your information or have any questions about your stored data and how it is protected,
          then please contact us at <a href="mailto:info@bankifi.com">info@bankifi.com</a>
        </Typography>

        <br />
        <Typography variant="body2">Pratura Lending</Typography>
        <Typography variant="body2">Version 1.0</Typography>
        <Typography variant="body2">March 2024</Typography>
        <br />
      </Box>
    </PageLayout>
  );
};

export default PrivacyNoticeScreen;
