import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PageLayout } from "@APP/components";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.body2.fontFamily,
    "& li": {
      ...theme.typography.body2,
    },
    "& ol": {
      paddingLeft: 20,
    },
    "& ol li": {
      paddingLeft: 20,
    },
    "& ul ul": {
      paddingLeft: 40,
    },
    "& p strong": {
      textDecoration: "underline",
    },
  },
}));

const TermsAndConditionsScreen = () => {
  const styles = useStyles();

  return (
    <PageLayout>
      <Box className={styles.root} maxWidth={980} alignSelf="center" id="termsAndConditionsBox">
        <Typography variant="h5">The Praetura Lending Web App Services – Key Terms.</Typography>
        <br />
        <ol type="A">
          <li>
            These Praetura Lending Terms and Conditions (the “Terms”) set out the terms that apply
            to your access to the Praetura Lending Web App and use of Our Services, including the
            Praetura Lending websites and Praetura Lending platform operated by us which support the
            service and access to the Account Information Services and Payment Initiation Services
            as provided by Moneyhub Technology Ltd (together “Our Service” or “Our Services”).&nbsp;
          </li>
          <br />
        </ol>
        <ol type="A" start={2}>
          <li>
            You should read these Terms as once you have registered for the Praetura Lending Web App
            they will form an agreement between you and us which you will be bound by. Our Services
            are intended to be used by business customers.
          </li>
          <br />
        </ol>
        <ol type="A" start={3}>
          <li>
            We have used certain words and phrases in the Terms which have the meanings ascribed to
            them below. When we say:
          </li>
          <br />
        </ol>
        <ul>
          <ul>
            <li>
              “BankiFi” or “we” or “us” or “our” we mean BankiFi Technology Limited. Our registered
              company number is 10696778. Our registered office is Centurion House, 129 Deansgate,
              Manchester, M3 3WR and we can be contacted at{" "}
              <u>
                <a href="mailto:info@bankifi.com">info@bankifi.com</a>
              </u>
              .
            </li>
            <br />
            <li>
              “you” or “your”, we mean the holder of the accounts whose account information will be
              accessed and who has registered an account to use the Praetura Lending Web App.
            </li>
            <br />
            <li>
              “Our partner”, we mean Praetura Group or any of their registered entities and
              Moneyhub.
            </li>
            <br />
            <li>“applicant” we mean you / your business who is applying for a loan service.</li>
            <br />
            <li>“payer”, we mean the person who you have asked to make a payment to you.</li>
            <br />
            <li>
              “Requests to Pay” we mean the messages that are sent between a Payee and a Payer in
              Our Services.
            </li>
            <br />
          </ul>
        </ul>
        <ol type="A" start={4}>
          <li>
            Our Services are made up of a number of elements which may (from time to time) include
            websites, our [ Praetura Lending] platform and our Payer Web Application. These terms
            provide an overarching framework of contractual terms that apply to your use of Our
            Services. Our service enables you/us to:
          </li>
          <br />
          <ol>
            <li>Apply for finance via asset, invoice or commercial loan options</li>
            <li>Display account balances from linked bank accounts </li>
            <li>Display outstanding invoices from linked accounting packages</li>
            <li>Create and send invoices to customers.</li>
            <li>
              Request a payment from a payer via Request to Pay (RtP). These payers are not required
              to register for Our Services and are therefore excluded from these terms.
            </li>
            <li>
              Transfer money between bank accounts which you own and which you have linked to Our
              Service.
            </li>
            <li>Make payments to suppliers from invoices you have received.</li>
            <li>View you cashflow forecast.</li>
          </ol>
        </ol>
        <br />
        <ol type="A" start={5}>
          <li>
            <b>The Moneyhub Services</b>: When you register for the Praetura Lending Web App, we
            will use Open Banking to access information from payment accounts which you hold with a
            bank or building society to help verify your identity and carry out anti money
            laundering checks. We shall also access your bank account to display balances to you
            within Our Service. The provision of information from your payment accounts is an
            Account Information Service, which is regulated under the Payment Services Regulations
            2017. When you request a payment from a customer (the “payer”), the payer may use Open
            Banking to initiate payment to you from an account they hold with a bank or building
            society. You may also use Open Banking to initiate payments to your suppliers and to
            move money between your linked bank accounts. Making a payment from a payment account
            using Open Banking is a Payment Initiation Service, which is regulated under the Payment
            Services Regulations 2017.
            <br />
            <br />
            BankiFi are acting as the registered agent of Moneyhub Financial Technology Ltd
            (“Moneyhub”), which provides the regulated Account Information and Payment Initiation
            Services (together the “Moneyhub Services”)
            <br />
            <br />
            Moneyhub Financial Technology Ltd is a limited company registered in England and Wales
            (company reg. no. 06909772) with its registered office and principal trading address at
            Regus House, 1 Friary, Temple Quay, Bristol, BS1 6EA; VAT number GB343601232. Moneyhub
            is registered with the Information Commissioner (No. Z2507137). Moneyhub is authorised
            by the Financial Conduct Authority under the Payment Services Regulations 2017
            (reference number: 809360) for the provision of payment services.
            <br />
            <br />
            To make payment the Payer accesses their own bank‘s mobile or web banking services via
            Moneyhub. Once logged in they are presented with a prefilled payment page. The Payer may
            not be registered with Our Service and therefore not bound by the Terms of Our Service.
            If the Payer does not wish to accept the RtP, they can decline the request and settle
            the payment through other means. Full conditions of use for the Moneyhub Services are
            available here:{" "}
            <u>
              <a href="https://content.moneyhub.co.uk/terms/api-terms-v1.pdf">
                https://content.moneyhub.co.uk/terms/api-terms-v1.pdf
              </a>
            </u>
          </li>
          <br />
        </ol>
        <ol type="A" start={6}>
          <li>
            <b>Disputes</b> If there is a dispute with a payment this needs to be settled directly
            between the Payee and the Payer, not through Our Service. We take no responsibility for
            the validity of the payment request as this is initiated and controlled entirely by the
            Payee, and a Payer can decline to make payment at any time.
          </li>
          <br />
        </ol>
        <ol type="A" start={7}>
          <li>
            <b>Your use of Moneyhub</b> By registering for the Praetura Lending Web App we will deem
            that you have agreed to Moneyhub’s conditions of use. If a payer wants to use the
            Moneyhub Services to make a payment to you, or if you use the Moneyhub service to make a
            supplier payment, there will be a requirement to agree to Moneyhub’s conditions of use
            as a condition of using the Moneyhub Services.
          </li>
          <br />
        </ol>
        <Typography variant="body2">
          By accepting these Terms, you are confirming that you have the authority to enter into and
          bind the account holder to these terms.
        </Typography>
        <br />

        <Typography variant="h5">The Terms</Typography>
        <br />
        <Typography variant="body2">
          <strong>1. Registering to use Our open cash management Service</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          1.1 In order to create and send invoices, issue RtPs to your customers, make payments to
          your suppliers or move money from your linked bank accounts through Our Service, you must
          register for Our Services through the Praetura Lending Web App. Whilst registering, you
          will be asked to confirm that you agree with these Terms of Use, and that you consent to
          Moneyhub proceeding with the Account Information Service and the Moneyhub terms and
          conditions. When you register, we will ask for information so that we can validate who you
          are in relation to your business. Some of this information is needed so that we can
          communicate with you and provide you with Our Service.
        </Typography>
        <br />
        <Typography variant="body2">
          1.2 We respect your personal information and understand its importance to you and
          therefore abide by all the relevant data protection legislation. We only use your personal
          data in accordance with our privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          1.3 When you register you will need to consent to Moneyhub having the right to access your
          bank account(s), so that they can monitor when outstanding payments have been paid into or
          from your account. To do this Moneyhub will use the standard, regulated, Open Banking
          consent process, and you will be redirected your online banking to give consent.
        </Typography>
        <br />
        <Typography variant="body2">
          1.4 You can remove this consent at any time through your bank’s online banking service.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>2. Sending RtPs</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          2.1 Once you have registered you can start to send RtPs to your Payers / customers. We may
          limit the amount and/or volume of payments you can request at any time if we reasonably
          consider there is a risk of fraud. When an RtP is sent it remains open (“Open Requests”)
          until it is either paid or declined by the Payer, or it is cancelled by you. You can
          cancel any RtPs that have not been paid (“Cancelled Requests”). When a Payer pays an RtP
          we will show that it has been paid (“Paid Requests”). We show you the history of Open
          Requests, Paid Requests and Cancelled Requests in the Praetura Lending Web App.
        </Typography>
        <br />
        <Typography variant="body2">
          2.2 We will let you know if a Payer rejects an RtP. We will also allow the Payer to block
          any future requests from you, for example if the Payer decides you are sending too many
          reminders to pay an Open Request.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>3. Linking Accounting Software</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          3.1 We provide you with the ability to link your accounting software (if you use Sage,
          Xero or QuickBooks) during registration or at any time in the Praetura Lending Web App.
          You can do so by logging into your accounting software through our Web App and then by
          following the in-Web App instructions. We never need to know any of your account
          credentials for your accounting software. If you do not have accounting software or do not
          wish to link accounting software, you have the option to use a native Invoicing service,
          which is built into the Praetura Lending Web App.
        </Typography>
        <br />
        <Typography variant="body2">
          If the information in your accounting software is not up to date, then the information
          presented in the Praetura Lending Web App may not be accurate. We do not accept any
          liability if you rely on any information in the Praetura Lending Web App.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>4. Leaving Our Service</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          4.1 You can leave Our Service at any time in a number of ways. You can opt out via a
          feature in the Web App or by contacting us. You will not be able to send any new RtPs or
          resend any Open Requests. RtPs which are open can still be paid. We shall also revoke
          consent(s) previously granted to access your Bank Account(s).
        </Typography>
        <br />
        <Typography variant="body2">
          Please note that simply deleting the Praetura Lending Web App without following one of the
          options described in the paragraph above will result in your account remaining open.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>5. Communicating with You</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          5.1 We will only communicate with you in order to operate or improve Our Service. We will
          not send you any marketing emails or messages from third parties. But when we introduce
          new features to Our Service, we will let you know.
        </Typography>
        <br />
        <Typography variant="body2">
          5.2 When we communicate, we will contact you using the email address you gave us when you
          registered. We will communicate with you in the English language.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>6. Using our Service in an Acceptable Way</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          6.1 To help us ensure that Our Service is safe and useful for all users, you agree that
          you will use Our Service only in accordance with these Terms of Use. We may change our
          Terms of Use if we change the services we provide. We will let you know by email each time
          the Terms are changed.
        </Typography>
        <br />
        <Typography variant="body2">
          6.2 If you breach our Terms of Use, we may disable or suspend your account.
        </Typography>
        <br />
        <Typography variant="body2">
          6.3 You will access and use Our Service only for legal, authorised, and acceptable
          purposes. You will not use or help another to use Our Service to:
        </Typography>
        <br />
        <ol type="a">
          <li>
            violate, misappropriate, or infringe the rights of BankiFi, our users, or others,
            including privacy, publicity, intellectual property, or other proprietary rights;
          </li>
          <li>
            instigate or encourage conduct that would be illegal or otherwise inappropriate
            including but not limited to: illegal, obscene, defamatory, threatening, intimidating,
            harassing, sexually explicit, hateful or offensive content;
          </li>
          <li>communicate falsehoods, misrepresentations, or misleading statements;</li>
          <li>impersonate someone; or</li>
          <li>
            send illegal or impermissible communications, or send RtPs to people who do not owe you
            money or who would not reasonably expect to receive an RtP from you.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          6.4 You will not, nor help others to, directly or indirectly, whether through automated
          means or otherwise, use, access, distribute, grant a licence or sub-licence (or purport to
          distribute, grant a licence or sub-licence), copy, modify, adapt, create derivative works
          from, or otherwise exploit BankiFi Technology Limited and/or Our Service beyond the usage
          allowed by the Terms.
        </Typography>
        <br />
        <Typography variant="body2">
          6.5 You will not, nor help others to, directly or indirectly, whether through automated
          means or otherwise:
        </Typography>
        <br />
        <ol type="a">
          <li>
            reverse engineer, decompile, or extract code (in whole or in part) from BankiFi
            Technology Limited or Our Service;
          </li>
          <li>
            send, store, or transmit viruses or other harmful computer code through or onto Our
            Service;
          </li>
          <li>gain or attempt to gain unauthorised access to Our Service;</li>
          <li>
            interfere with or disrupt the safety, security, or performance Our Service, our systems
            or platform;
          </li>
          <li>
            create accounts for use in Our Service through unauthorised or automated means or
            circumvent our anti-money laundering or anti-fraud measures or attempt to do so;
          </li>
          <li>
            abuse Our Service (or attempt to do so) by collecting information of or about the system
            or its users in any impermissible or unauthorised manner;
          </li>
          <li>
            sell, resell, grant a licence or sub-licence, distribute or charge or purport to take
            any similar actions for BankiFi when you are not authorised in writing by us to do so;
          </li>
          <li>
            create software, a platform or APIs that function substantially the same as Our Service
            having copied Our Service and offer them for use by anyone else in an unauthorised
            manner.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          6.6 If we reasonably suspect you are committing any fraudulent activity through Our
          Service, we may terminate your account at any time without notice and provide your details
          to the relevant authorities. &nbsp;&nbsp;
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>7. Licences and Use of Data</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          7.1 You and we grant each other certain rights so that you can use Our Service. You own
          the information that you send through Our Service. We use this information via the consent
          granted to Moneyhub as part of Our Service. We have no other rights to use this consent
          for any other purpose, nor will we pass or sell your data to anyone outside of BankiFi
          Technology Limited and Praetura Group or their associated companies or otherwise as set
          out in our privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          7.2 We and our partners own all copyrights, trademarks, domains, logos, trade dress, trade
          secrets, patents, and other intellectual property rights associated with the Praetura
          Lending Web App and Our Service. You may not use such copyrights, trademarks, domains,
          logos, trade dress, patents, or other intellectual property rights unless you have our
          prior express written permission and in accordance with our and/or our partners brand
          purpose, guidelines, and values.
        </Typography>
        <br />
        <Typography variant="body2">
          7.3 We grant you a limited, revocable, non-exclusive, non-sublicensable, and
          non-transferable licence to use Our Service, subject to and in accordance with these
          terms. This licence is for the sole purpose of enabling you to use Our Service as we
          describe in these terms. No other licences or rights are granted to you by implication or
          otherwise.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>8. Limitation of Our Liability</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          8.1 Our Service is designed to be available to use as much as possible 24 hours a day, 7
          days a week subject to planned and unplanned outages. We use our reasonable endeavours to
          reduce any outages and to give warning when an outage is planned. We also schedule planned
          outages during the hours when the service has the minimum usage.
        </Typography>
        <br />
        <Typography variant="body2">
          8.2 When we use Moneyhub’s Account Information Services as part of registering a Payee or
          when a Payer registers with Moneyhub to make a payment, we and Our Partners rely upon the
          relevant bank’s Open Banking connectivity, which may not be available 100% of the time. If
          their connection is unavailable for whatever reasons, we, in these circumstances cannot be
          held liable. But we will let the Payer or Payee know if we are able to.
        </Typography>
        <br />
        <Typography variant="body2">
          8.3 When we attempt to produce an RtP from an invoice or mark an invoice as paid through
          the link to Accounting Software, we rely upon the relevant Accounting Software
          connectivity, which may not be available 100% of the time. If their connection is
          unavailable for whatever reason, we in these circumstances cannot be held liable. But we
          will let the Payer or Payee know if we are able to.
        </Typography>
        <br />
        <Typography variant="body2">
          8.4 Events beyond our control may also affect Our Service (“Force Majeure Events”). If a
          Force Majeure Event occurs, we apologise for any inconvenience it may cause and you can be
          assured that we will take all reasonable actions to get Our Service back up and running
          quickly. We are not liable for the non-provision of Our Service or any losses, howsoever
          arising, as a result of a Force Majeure Event.
        </Typography>
        <br />
        <Typography variant="body2">
          8.5 Our Service is provided on an “as is” and “as available” basis and we do not make any
          warranties, claims or representations with respect to the Our Service including, without
          limitation, quality, performance, non-infringement, merchantability or fitness for use for
          a particular purpose. We do not represent or warrant that availability or use of Our
          Service will be uninterrupted, timely, secure, error-free or virus-free.
        </Typography>
        <br />
        <Typography variant="body2">
          8.6 We do not accept responsibility or liability for a failure of your mobile network, or
          internet provider, or any losses or damage suffered as a result, as this is outside our
          control, as well as keeping all such connecting devices safe and secure and paying any
          mobile, data and or internet connection and service charges.
        </Typography>
        <br />
        <Typography variant="body2">
          8.7 We use reasonable endeavours to ensure that Our Service does not contain or spread any
          viruses or other malicious code. However, we recommend that you regularly check your
          devices for the presence of viruses and other malicious code. We exclude to the fullest
          extent permitted by Web Applicable laws all liability in connection with any damage or
          loss caused by computer viruses or other malicious code originating or contracted from Our
          Service.
        </Typography>
        <br />
        <Typography variant="body2">
          8.8 You should note that the transmission of information via the internet is not
          completely secure, and we do everything we can to keep all the information we store safe
          and secure. Any transmission of information via the internet is at your own risk. We are
          not responsible for circumvention of any privacy settings or security measures contained
          on our platform and we exclude to the fullest extent permitted by law all liability in
          connection with the circumvention of any privacy settings or security measures contained
          on our platform.
        </Typography>
        <br />
        <Typography variant="body2">
          8.9 Where we use Open Banking to check when a payment has been made to your account or use
          your Accounting Software to create RtPs from invoices, we are not responsible for the
          accuracy of information we receive from the organisations that you have accounts with. We
          will relay the exact information we are given.
        </Typography>
        <br />
        <Typography variant="body2">
          8.10 We will not be liable in the event Our Service is proven to be faulty unless a claim
          is notified to us in writing. The claim will need to include details we need to identify
          the RtP(s), the Payee and the Payer involved. The claim must be made within 35 days of the
          RtP in question being sent (if a complaint is being made by the Payee) or received (if a
          complaint is being made by a Payer). If you fail to notify us of a problem in accordance
          with this Term, we will not be held liable for that problem. In the case of a valid claim,
          we will follow our complaints policy. We will have no further liability to you in respect
          of that claim. If there is an issue with the payment transaction, then the Moneyhub
          service terms will apply.
        </Typography>
        <br />
        <Typography variant="body2">
          8.11 If your complaint relates to Moneyhub Services, details of Moneyhub’s complaints
          process can be found in the Moneyhub conditions of use. If you make a complaint to us, but
          we believe that Moneyhub may be solely or jointly responsible for the matter alleged we
          may forward your complaint to Moneyhub. We will tell you if we do this.
          <br />
          We’ll send you a written acknowledgement within five working days and keep you informed of
          our progress until your complaint has been resolved. We’ll do everything we can to sort
          out the problem.
          <br />
          If you still remain dissatisfied, you may be entitled to refer your complaint to the
          ombudsman service using the contact details set out below. If you’re not eligible to use
          the ombudsman service, please note we don’t use any alternative dispute resolution
          services.
        </Typography>
        <br />
        <ul>
          <li>Exchange Tower, London, E14 9SR</li>
          <li>
            <a href="https://www.financial-ombudsman.org.uk" target="_blank" rel="noreferrer">
              www.financial-ombudsman.org.uk
            </a>
          </li>
          <li>
            <a href="mailto:complaint.info@financial–ombudsman.org.uk">
              complaint.info@financial–ombudsman.org.uk
            </a>
          </li>
        </ul>
        <br />
        <Typography variant="body2">
          8.12 We, our agents, directors, officers, shareholders, employees and subcontractors of
          BankiFi and our subsidiary will not be liable to you or anyone else, whether in contract,
          tort (including negligence, breach of statutory duty or other tort) or otherwise, for any:
        </Typography>
        <br />
        <ol type="a">
          <li>
            loss of data, sales or business, agreements or contracts, anticipated savings, profits,
            opportunity, goodwill or reputation, or for any business interruption;
          </li>
          <li>loss or corruption of data; or</li>
          <li>
            indirect, special or consequential loss, damage, costs or other claims, howsoever caused
            or arising, including where arising directly or indirectly from any failure or delay in
            performing any obligation under these terms caused by matters beyond our reasonable
            control. Except as expressly stated elsewhere in these terms, all representations,
            warranties, conditions and other terms, whether express or implied (by common law or
            statute) are hereby excluded, except in the case of fraud, death and personal injury, or
            where such exclusion is not permitted by law.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          8.13 Our aggregate liability to you, arising out of or in connection with providing you
          with Our Service under these Terms, shall be limited to losses that are reasonably
          foreseeable and which arose because of our breach of these terms or negligence (except in
          relation to death, personal injury, or fraudulent misrepresentation which we do not
          limit), and will not exceed the amount you have paid us in the past twelve months.
        </Typography>
        <br />
        <Typography variant="body2">
          8.14 All views of your payments, accounts and RtPs (sent and received) and all the
          capabilities within Our Service are to help you manage your own payments and finances. We
          do not recommend any particular action (or inaction). We are not your advisors. You must
          make your own decisions about your own payments, accounts and finances.
        </Typography>
        <br />
        <Typography variant="body2">
          8.15 Our Service is a secure messaging and communication service allowing Payees and
          Payers to send and receive RtPs and payments simply, swiftly and securely. BankiFi
          Technology Limited is not responsible for collecting or holding money, lending or ensuring
          bills are affordable, paid, actioned or read.
        </Typography>
        <br />
        <Typography variant="body2">
          8.16 We are not liable for any payments made by a Payer through Our Service that are not
          due to be paid. Any dispute between a Payee and a Payer must be settled directly between
          the Payee and Payer and not through Our Service. If a RtP is received by a Payer and the
          Payer is not certain that the payment is due or the Payee is the correct recipient of the
          payment then the Payer should not make the payment.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>9. Indemnification</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          9.1 You will indemnify us, our subsidiary and our directors and officers (acting
          reasonably) from any and all third-party claims, liabilities, damages, expenses and costs
          (including, but not limited to, reasonable solicitors’ fees) caused by or arising from
          your use of Our Service, your violation of these terms or your infringement of any
          intellectual property or other such right of any third party.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>10. Suspicious or Unlawful Conduct</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          10.1 We may wish to modify, suspend, or block your access to or use of Our Service at any
          time for suspicious or unlawful conduct, including for fraud, or if we reasonably believe
          you breach our terms or create harm, risk, or possible legal exposure for us, our users,
          or others. If we do this, the following clauses will still be in existence between us and
          will survive any termination of our relationship: Licences, Indemnification, Limitation of
          our Liability and Legal and Definitions. If you believe your account has been blocked or
          suspended by mistake, please contact us using{" "}
          <a href="mailto:info@bankifi.com">info@bankifi.com</a>
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>11. General&nbsp;</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          11.1 As a user of Our Service you agree to use it in accordance with our terms, which
          include behaving fairly and considerately. We have designed Our Service to be safe and
          secure from a technology perspective. We work to protect the use of Our Service by system
          monitoring for inappropriate behaviour and spamming. We prohibit the misuse of Our
          Service. We will take action as appropriate where we become aware of such activity. We may
          contact relevant law enforcement bodies if we think it is appropriate to do so. In the
          event outside law enforcement bodies become involved, we will share relevant information
          with them when we are required to.
        </Typography>
        <br />
        <Typography variant="body2">
          11.2 In order for Our Service to operate properly, you will need to manually or
          automatically download and install updates when they are available. This helps to keep Our
          Service secure, up-to-date, relevant and useful.
        </Typography>
        <br />
        <Typography variant="body2">
          11.3 We have designed and built Our Service for the UK and it complies with all laws of
          England and Wales. There may be differences abroad and you are responsible for your use of
          Our Service abroad, and any mobile/internet/data charges that you may incur.
        </Typography>
        <br />
        <Typography variant="body2">
          11.4 Unless we have entered into a separate contract, these Terms make up the entire
          agreement between you and us regarding Our Service and the Praetura Lending Web App. These
          Terms supersede and extinguish all previous agreements, promises, assurances, warranties,
          representations and understandings between us, whether written or oral, and you confirm
          you haven’t entered into this agreement on the basis of, or relying on, any of these.
        </Typography>
        <br />
        <Typography variant="body2">
          11.5 Our Service is not intended for distribution to or use in any country where such
          distribution or use would break local law or would subject BankiFi Technology or Our
          Service to regulations in another country.
        </Typography>
        <br />
        <Typography variant="body2">
          11.6 Any amendment or waiver to these terms requires our prior written consent.
        </Typography>
        <br />
        <Typography variant="body2">
          11.7 All our rights and obligations in these terms are assignable by us to our subsidiary
          or affiliated companies or in connection with a merger, acquisition, restructuring, or
          sale of assets, or by operation of law or otherwise, and we may transfer your information
          to our subsidiary, affiliates, successor entities, or new owner once we have assured
          ourselves appropriate security and safeguards are in place. In the event of such an
          assignment, these terms will continue to govern your relationship with such third party.
          If you do not agree to these terms, you can leave Our Service.
        </Typography>
        <br />
        <Typography variant="body2">
          11.8 You will not transfer any of your rights or obligations under these terms to anyone
          else without our prior written consent.
        </Typography>
        <br />
        <Typography variant="body2">
          11.9 Nothing in our terms will prevent us from complying with the law.
        </Typography>
        <br />
        <Typography variant="body2">
          11.10 Except where we specifically describe in these terms, our terms do not give any
          rights to third parties.
        </Typography>
        <br />
        <Typography variant="body2">
          11.11 If we do not enforce any of our terms, this does not mean that we have waived them
          and we reserve our rights to take action, in accordance with these terms.
        </Typography>
        <br />
        <Typography variant="body2">
          11.12 If any provision of these terms is found to be unlawful, void, or for any reason
          unenforceable, then we will remove that provision and the rest shall remain in force.
        </Typography>
        <br />
        <Typography variant="body2">
          11.13 Our terms are not intended to limit your legal rights as a consumer or data subject;
          aside from this and the liability exclusions outlined in the Liability clause, we reserve
          all rights not expressly granted by us to you. Your rights as a data subject are set out
          in our privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          11.14 These terms of use and any non-contractual rights and obligations arising in
          connection with them shall be governed by and construed in accordance with the laws of
          England and Wales. We and you submit to the exclusive jurisdiction of the courts of
          England and Wales.
        </Typography>

        <br />
        <Typography variant="body2">
          <strong>12. Protecting your user details</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          12.1 You must not disclose your login details for the Praetura Lending Web App to anyone.
          If you know or suspect someone else knows your login details, you must change them as soon
          as possible.
        </Typography>
        <br />
        <Typography variant="body2">
          12.2 We accept no responsibility for any losses or damages resulting from you sharing your
          login details.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>13. Ending your access to Our Services</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          13.1 You can end your access to the Praetura Lending Web App and Our Services at any time
          by following the processes set out in section 4.
        </Typography>
        <br />
        <Typography variant="body2">
          13.2 We can temporarily suspend or permanently cancel your access to the Praetura Lending
          Web App and Our Services on two months’ notice if we decide to withdraw the Praetura
          Lending Web App or any of Our Services. We can temporarily suspend or permanently cancel
          your access to the Praetura Lending Web App and Our Services immediately if:
        </Typography>
        <br />
        <ol type="a">
          <li>a third party who provides the relevant service ceases to provide it;</li>
          <li>
            we reasonably believe that you have been misusing the Praetura Lending Web App or Our
            Services (or intend to do so);
          </li>
          <li>you are in material breach of these Terms or Moneyhub’s conditions of use; or</li>
          <li>
            we are unable to provide the Praetura Lending Web App and Our Services for reasons
            beyond our control.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          13.3 In any of the circumstances above your access to the Praetura Lending Web App and Our
          Services will cease, and you will have no further access to them.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>14. Changes to these terms</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          14.1 From time to time we may amend these terms, including withdrawing or making changes
          to the Praetura Lending Web App, the length of the free period or Our Services or the
          Moneyhub Services provided through the Praetura Lending Web App. We may also change the
          amount we charge you for Our Services following the process set out in clause 7.2.
        </Typography>
        <br />
        <Typography variant="body2">
          14.2 For changes relating to the Moneyhub Services, we will normally give you two months’
          notice. For any other changes we will normally give you 30 calendar days’ notice, unless
          it is not practical to do so, in which case we will tell you as soon as we can after the
          change takes effect.
        </Typography>
        <br />
        <Typography variant="body2">
          14.3 From time to time we may decide to provide additional services. We may change these
          terms to include those additional services without giving prior notice to you. We will,
          however, send you a copy of the updated terms by email at the time the changes are made
          using the email address you provided when you registered for Praetura Lending Once you
          have been notified of a change, by continuing to use the Praetura Lending Web App or Our
          Services, you will be considered to have accepted the new terms.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>15. Web Applicable law</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          15.1 These Terms and any dispute or claim arising out of or in connection with them or
          their subject matter or formation (including non-contractual disputes or claims) shall be
          governed by and construed in accordance with the law of England and Wales. We both agree
          that the courts of England and Wales shall have exclusive jurisdiction to settle any
          dispute or claim arising out of or in connection with these Terms or their subject matter
          (including non-contractual disputes or claims).
        </Typography>
        <br />
        <Typography variant="body2">Praetura Lending V1.0</Typography>
        <Typography variant="body2">March 2024</Typography>
        <br />
      </Box>
    </PageLayout>
  );
};

export default TermsAndConditionsScreen;
